import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { promotions, mobilePromotion, promo1, promo2, promo3 } from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}
export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center m="0 auto" w="100%">
          <CFImage src={mobilePromotion} w="100%" alt="Promotions" />
        </CFView>
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={promo1}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={promo2}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={promo3}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="0 auto" w="100%">
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
